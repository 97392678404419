import moment, {Moment} from 'moment';
import {isDefined} from './is-defined';

// Attention: The patterns for momentjs differ from the ones
// used inside the angular date pipe!
// Example: dd.MM.yyyy is not 15.01.2024, it is Mo 01.2024 in momentjs
// Example: DD is not a valid date pipe pattern
export const SHORT_DATE_FORMAT = 'DD.MM.YYYY';
export const GERMAN_SHORT_DATE_FORMAT = 'dd.MM.yyyy';
export const GERMAN_DATE_TIME = 'dd.MM.yyyy, HH:mm';
export const ENGLISH_SHORT_DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_YEAR_FORMAT = 'MM.yyyy';
export const HOUR_MINUTES_FORMAT = 'HH:mm';
export const LOCAL_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss';

// === Date Utils ===
export function formatDate(dateString: Date | string | undefined | null, formatString = SHORT_DATE_FORMAT): string {
  if (!dateString) return '';
  return moment(dateString).format(formatString);
}

export function UTCdate(format: string = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'): string {
  const dateValue = moment();
  return moment(dateValue).format(format);
}

export function stringToDate(dateString: string | undefined, dateFormat: string = SHORT_DATE_FORMAT): Date {
  return moment(dateString, dateFormat).startOf('day').toDate();
}

export function addToDate(dateString: string, weeks: number, days: number): string {
  const date: Date = moment(dateString, ENGLISH_SHORT_DATE_FORMAT).add(weeks, 'weeks').add(days, 'days').toDate();
  return formatDate(date, ENGLISH_SHORT_DATE_FORMAT);
}

// === Time Utils ===

/**
 * Converts given amount of minutes to a human readable format.
 * Examples:
 * - 40mins -> 40m
 * - 75mins -> 1h15m
 * - 120mins -> 2h
 * @param minutes
 * @returns
 */
export function formatMinutesToHumanReadable(minutes: number | undefined): string {
  if (!isDefined(minutes)) return '';

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0 && remainingMinutes > 0) {
    return `${hours}h${remainingMinutes}m`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else {
    return `${remainingMinutes}m`;
  }
}

/**
 * This function concatenates the given Moment and hoursMinutes time string in HH:mm format.
 * @param date
 * @param hoursMinutes
 * @returns
 */
export function combineDateAndTime(
  date: Moment | null | undefined,
  hoursMinutes: string | undefined | null,
  isUtc = true
): Moment {
  if (!date) return moment.invalid();
  if (!hoursMinutes) return date;
  const [hours, minutes] = parseHoursMinutes(hoursMinutes);

  const timeObj = {
    year: date.year(),
    month: date.month(),
    date: date.date(),
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0
  };
  return isUtc ? moment.utc(timeObj) : moment(timeObj);
}

export function parseHoursMinutes(hoursMinutes: string | null | undefined): [number | undefined, number | undefined] {
  if (!hoursMinutes) return [undefined, undefined];
  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  if (!timeRegex.test(hoursMinutes)) {
    throw new Error(`Time string given ${hoursMinutes} does not conform to the format HH:mm!`);
  }

  const [hours, minutes] = hoursMinutes.split(':').map(Number);
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    throw new Error(`Hours must be within [0,23] and minutes within [0,59]!`);
  }

  return [hours, minutes];
}
